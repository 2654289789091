<template>
  <v-container class="fill-height">
    <v-row align="center" justify="center">
      <v-col cols="12" md="6">
        <v-card class="pa-4">
          <v-card-title>Subscribe to Our Newsletter</v-card-title>
          <v-text-field
            v-model="email"
            label="Enter your email"
            type="email"
            :rules="[rules.required, rules.email]"
            outlined
            class="mb-4"
          ></v-text-field>
          <v-btn
            :loading="isLoading"
            :disabled="!email || !isValidEmail"
            color="primary"
            @click="submitEmail"
          >
            Subscribe
          </v-btn>

          <!-- Snackbar Notification -->
          <v-snackbar
            v-model="showSnackbar"
            :color="snackbarColor"
            timeout="3000"
            top
          >
            {{ snackbarMessage }}
          </v-snackbar>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios';

export default {
  name: 'NewsletterPage',
  data() {
    return {
      email: '',
      isLoading: false,
      showSnackbar: false,
      snackbarMessage: '',
      snackbarColor: 'success',
      rules: {
        required: (v) => !!v || 'Email is required',
        email: (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      },
    };
  },
  computed: {
    isValidEmail() {
      return this.rules.email(this.email) === true;
    },
  },
  methods: {
    async submitEmail() {
      this.isLoading = true;
      try {
        const response = await axios.post('/api/subscribe-newsletter', {
          email: this.email,
        });
        this.snackbarMessage = response.data.message;
        this.snackbarColor = 'green';
      } catch (error) {
        this.snackbarMessage = error.response?.data?.detail || 'Error subscribing';
        this.snackbarColor = 'red';
      } finally {
        this.isLoading = false;
        this.showSnackbar = true;
        this.email = '';
      }
    },
  },
};
</script>

<style scoped>
.v-card {
  max-width: 500px;
  margin: auto;
}
</style>

