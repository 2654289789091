<template>
  <v-container class="fill-height">
    <v-row align="center" justify="center">
      <v-col cols="12" md="6">
        <v-card class="pa-4">
          <v-card-title class="text-h5 text-center">
            Buy 50 Credits for $5
          </v-card-title>

          <!-- Payment Form -->
          <v-form @submit.prevent="handleSubmit">
            <!-- Stripe Card Element -->
            <div
              id="card-element"
              class="my-4 pa-4 rounded border"
            ></div>

            <!-- Submit Button -->
            <v-btn
              :loading="isProcessing"
              :disabled="isProcessing"
              type="submit"
              color="primary"
              block
            >
              Pay Now
            </v-btn>
          </v-form>

          <!-- Error Message -->
          <v-alert
            v-if="error"
            type="error"
            class="mt-4"
            dense
          >
            {{ error }}
          </v-alert>

          <!-- Success Message -->
          <v-alert
            v-if="success"
            type="success"
            class="mt-4"
            dense
          >
            Payment successful! Credits have been added to your account.
          </v-alert>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import { ref, onMounted, getCurrentInstance } from 'vue';
import { useAuth0 } from '@auth0/auth0-vue';

export default {
  name: 'PaymentPage',
  setup() {
    const stripe = ref(null);
    const cardElement = ref(null);
    const error = ref(null);
    const success = ref(false);
    const isProcessing = ref(false);

    const { getAccessTokenSilently } = useAuth0();
    const { proxy } = getCurrentInstance();

    onMounted(async () => {
      const stripePublishableKey = proxy.$stripePublishableKey;

      if (stripePublishableKey) {
        stripe.value = await loadStripe(stripePublishableKey);
        const elements = stripe.value.elements();
        cardElement.value = elements.create('card');
        cardElement.value.mount('#card-element');
      } else {
        error.value = 'Stripe publishable key not found.';
      }
    });

    const handleSubmit = async () => {
      isProcessing.value = true;
      error.value = null;

      try {
        const token = await getAccessTokenSilently();

        const { data } = await axios.post(
          '/api/create-payment-intent',
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const clientSecret = data.clientSecret;

        const { error: stripeError, paymentIntent } =
          await stripe.value.confirmCardPayment(clientSecret, {
            payment_method: {
              card: cardElement.value,
            },
          });

        if (stripeError) {
          error.value = stripeError.message;
        } else if (paymentIntent.status === 'succeeded') {
          success.value = true;
        }
      } catch (e) {
        error.value = e.response?.data?.detail || 'An error occurred during payment.';
      } finally {
        isProcessing.value = false;
      }
    };

    return {
      error,
      success,
      isProcessing,
      handleSubmit,
    };
  },
};
</script>
