<template>
  <v-container fluid>
    <!-- Hero Section -->
    <v-parallax
      height="400"
      src="https://cdn.vuetifyjs.com/images/parallax/material.jpg"
    >
      <v-row class="fill-height" align="center" justify="center">
        <v-col cols="12" md="8" class="text-center">
          <h1 class="display-2 font-weight-bold white--text">
            Boost Your Social Media Presence
          </h1>
          <h4 class="white--text">
            Generate engaging posts with AI-powered assistance
          </h4>
          <v-btn
            color="primary"
            large
            class="mt-5"
            @click="$router.push('/social-media')"
          >
            Get Started
          </v-btn>
        </v-col>
      </v-row>
    </v-parallax>

    <!-- Dynamic Counter Section -->
    <v-container class="my-10 text-center">
      <v-row>
        <v-col cols="12" md="4">
          <h2 id="hours-saved" class="display-3 font-weight-bold">0</h2>
          <p>Hours Saved</p>
        </v-col>
        <v-col cols="12" md="4">
          <h2 id="users" class="display-3 font-weight-bold">0</h2>
          <p>Happy Users</p>
        </v-col>
        <v-col cols="12" md="4">
          <h2 id="posts-generated" class="display-3 font-weight-bold">0</h2>
          <p>Posts Generated</p>
        </v-col>
      </v-row>
    </v-container>

    <!-- Features Section -->
    <v-container class="my-10">
      <v-row>
        <v-col cols="12" md="4">
          <v-card outlined class="hoverable">
            <v-card-title>
              <v-icon large color="primary">mdi-robot</v-icon>
              <span class="ml-3">AI-Powered Content</span>
            </v-card-title>
            <v-card-text>
              Leverage advanced AI to generate captivating social media posts tailored for your audience.
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" md="4">
          <v-card outlined class="hoverable">
            <v-card-title>
              <v-icon large color="primary">mdi-speedometer</v-icon>
              <span class="ml-3">Save Time</span>
            </v-card-title>
            <v-card-text>
              Quickly create posts without the hassle of brainstorming and writing content yourself.
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" md="4">
          <v-card outlined class="hoverable">
            <v-card-title>
              <v-icon large color="primary">mdi-account-group</v-icon>
              <span class="ml-3">Grow Your Audience</span>
            </v-card-title>
            <v-card-text>
              Engage more users and expand your reach on social media platforms.
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <!-- Call to Action Section -->
    <v-parallax
      height="300"
      src="https://cdn.vuetifyjs.com/images/parallax/material2.jpg"
    >
      <v-row class="fill-height" align="center" justify="center">
        <v-col cols="12" md="8" class="text-center">
          <h2 class="display-2 font-weight-bold white--text">
            Ready to Elevate Your Social Media?
          </h2>
          <v-btn
            color="primary"
            large
            class="mt-5"
            @click="$router.push('/payment')"
          >
            Buy Credits Now
          </v-btn>
        </v-col>
      </v-row>
    </v-parallax>

    <!-- Testimonials Section -->
    <v-container class="my-10">
      <v-row>
        <v-col cols="12">
          <h2 class="text-center mb-6">What Our Users Say</h2>
        </v-col>
        <v-col
          cols="12"
          md="4"
          v-for="(testimonial, index) in testimonials"
          :key="index"
        >
          <v-card class="testimonial-card hoverable">
            <v-card-text class="text-center">
              <v-avatar size="80" class="mb-4">
                <v-img :src="testimonial.image"></v-img>
              </v-avatar>
              <p class="mb-4">"{{ testimonial.quote }}"</p>
              <strong>{{ testimonial.name }}</strong><br />
              <em>{{ testimonial.position }}</em>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <!-- Newsletter Section -->
    <v-parallax
      height="250"
      src="https://cdn.vuetifyjs.com/images/parallax/material3.jpg"
    >
      <v-row class="fill-height" align="center" justify="center">
        <v-col cols="12" md="6" class="text-center">
          <h2>Stay Updated</h2>
          <p>
            Subscribe to our newsletter to receive the latest news and exclusive offers.
          </p>
          <!-- Newsletter Form -->
          <v-form ref="form" v-model="formValid">
            <v-text-field
              v-model="email"
              label="Your Email"
              type="email"
              :rules="[rules.required, rules.email]"
              outlined
              color="primary"
              class="mb-3"
              :error-messages="emailErrors"
              @input="onInput"
            ></v-text-field>
            <v-btn
              :loading="isLoading"
              :disabled="!formValid"
              color="primary"
              large
              @click="submitEmail"
            >
              Subscribe Now
            </v-btn>
          </v-form>

          <!-- Snackbar Notification -->
          <v-snackbar
            v-model="showSnackbar"
            :color="snackbarColor"
            timeout="3000"
            top
          >
            {{ snackbarMessage }}
            <template v-slot:action="{ attrs }">
              <v-btn text v-bind="attrs" @click="showSnackbar = false">
                Close
              </v-btn>
            </template>
          </v-snackbar>
        </v-col>
      </v-row>
    </v-parallax>
  </v-container>
</template>

<script>
import { ref, onMounted, computed } from 'vue';
import { CountUp } from 'countup.js';
import axios from 'axios';

export default {
  name: 'HomePage',
  setup() {
    // Email Subscription States
    const email = ref('');
    const isLoading = ref(false);
    const showSnackbar = ref(false);
    const snackbarMessage = ref('');
    const snackbarColor = ref('success');
    const formValid = ref(false);
    const form = ref(null);

    const rules = {
      required: (v) => !!v || 'Email is required',
      email: (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    };

    const emailErrors = ref([]);

    const isValidEmail = computed(() => rules.email(email.value) === true);

    const submitEmail = async () => {
      isLoading.value = true;
      try {
        const response = await axios.post('/api/subscribe-newsletter', {
          email: email.value,
        });
        snackbarMessage.value = response.data.message;
        snackbarColor.value = 'green';
        // Reset the form
        if (form.value) {
          form.value.reset();
        }
      } catch (error) {
        snackbarMessage.value =
          error.response?.data?.detail || 'Error subscribing';
        snackbarColor.value = 'red';
      } finally {
        isLoading.value = false;
        showSnackbar.value = true;
      }
    };

    const onInput = () => {
      if (form.value) {
        form.value.validate();
      }
    };

    // Dynamic Counters
    const testimonials = ref([
      {
        image: 'https://randomuser.me/api/portraits/men/1.jpg',
        quote:
          'This service has transformed our social media strategy! Highly recommended.',
        name: 'John Doe',
        position: 'Marketing Manager',
      },
      {
        image: 'https://randomuser.me/api/portraits/women/2.jpg',
        quote:
          'Creating posts has never been easier. The AI suggestions are spot on!',
        name: 'Jane Smith',
        position: 'Social Media Specialist',
      },
      {
        image: 'https://randomuser.me/api/portraits/men/3.jpg',
        quote:
          'An essential tool for any business looking to enhance their online presence.',
        name: 'Mike Johnson',
        position: 'CEO, Startup Inc.',
      },
    ]);

    onMounted(() => {
      // Simulate data fetching with a delay
      setTimeout(() => {
        const options = {
          separator: ',',
          decimal: '.',
          duration: 3,
        };

        const hoursSavedCounter = new CountUp('hours-saved', 2500, options);
        hoursSavedCounter.start();

        const usersCounter = new CountUp('users', 1500, options);
        usersCounter.start();

        const postsGeneratedCounter = new CountUp(
          'posts-generated',
          10000,
          options
        );
        postsGeneratedCounter.start();
      }, 1000); // Delay to simulate API call
    });

    return {
      email,
      isLoading,
      showSnackbar,
      snackbarMessage,
      snackbarColor,
      rules,
      emailErrors,
      submitEmail,
      form,
      formValid,
      onInput,
      testimonials,
    };
  },
};
</script>

<style scoped>
.white--text {
  color: white !important;
}

.testimonial-card {
  transition: transform 0.3s;
}
.testimonial-card:hover {
  transform: translateY(-10px);
}
</style>
