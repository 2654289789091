// Vue related imports
import { createApp } from "vue";
import router from "./router";

// Auth0
import { createAuth0 } from "@auth0/auth0-vue";

// Google analytics
import VueGtag from "vue-gtag";

// Import Vuetify and styles
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import '@mdi/font/css/materialdesignicons.css';

// Layout
import App from "./App.vue";

const vuetify = createVuetify({
    components,
    directives,
    theme: {
       defaultTheme: 'light',
    }
})

const app = createApp(App);

const initializeApp = (google_analytics_id, auth0Config, stripePublishableKey) => {
  if (google_analytics_id) {
    app.use(
      VueGtag,
      {
        config: { id: google_analytics_id },
      },
      router
    );
  }

  if (auth0Config) {
    app.use(
      createAuth0({
        domain: auth0Config.domain,
        clientId: auth0Config.clientId,
        authorizationParams: {
          audience: auth0Config.apiAudience,
          redirect_uri: window.location.origin,
        },
        cacheLocation: "localstorage",
      })
    );
  }

  // Set the Stripe publishable key as a global property
  app.config.globalProperties.$stripePublishableKey = stripePublishableKey;

  app.use(router);
  app.use(vuetify);
  app.mount("#app");
};


// Fetch the configuration from the backend
fetch("/api/config")
  .then((response) => {
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return response.json();
  })
  .then((config) => {
    console.log("Fetched config:", config);

    // Initialize the app after config is fetched
    initializeApp(
      config.google_analytics_id,
      config.auth0,
      config.stripe.publishableKey
    );
  })
  .catch((error) => {
    console.error("Failed to load configuration:", error);

    // Initialize the app even in case of error, with no config (or provide fallback config)
    initializeApp(null, null, null);
  });
