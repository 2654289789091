<template>
  <v-container class="fill-height">
    <v-row align="center" justify="center">
      <v-col cols="12" md="8">
        <v-card class="pa-6">

          <!-- Step 1: Company Description Section -->
          <v-card-title class="headline">Step 1: Set Up Your Company Profile</v-card-title>

          <!-- Manual Company Description (Step 1) -->
          <v-textarea v-model="companyDescription" label="Company Description" rows="5" class="mb-3"
            clearable></v-textarea>

          <!-- Save Description Button -->
          <v-row justify="center">
            <v-btn :loading="isLoading" @click="saveDescription" color="primary" dark class="mb-5">
              Save Description
            </v-btn>
          </v-row>

          <v-divider v-if="descriptionSaved"></v-divider>

          <div v-if="descriptionSaved" class="mt-5">
            <v-card-title>Step 2: Create Social Media Posts</v-card-title>

            <!-- Social Post Content -->
            <v-textarea v-model="socialPostContent" label="What is Your Social Post About?" rows="5" class="mb-3"
              clearable></v-textarea>

            <!-- Tone Slider -->
            <div class="mt-4">
              <v-row justify="space-between" class="px-2">
                <span>Informal</span>
                <span>Formal</span>
              </v-row>
              <v-slider v-model="postTone" min="0" max="100" step="1"></v-slider>
            </div>

            <!-- Generate Posts Button -->
            <v-row justify="center">
              <v-btn :loading="isGeneratingPosts" :disabled="isGeneratingPosts || !socialPostContent"
                @click="generatePosts" color="primary" dark class="mt-4 mb-5">
                Generate Posts
              </v-btn>
            </v-row>

            <!-- Display Generated Posts -->
            <div v-if="Object.keys(posts).length > 0">
              <v-card v-for="(post, platformName) in posts" :key="platformName" class="mb-4">
                <v-card-text>
                  <v-row align="center">
                    <!-- Platform Icon -->
                    <v-col cols="auto">
                      <v-avatar>
                        <v-img :src="platformLogos[platformName]" :alt="platformName"></v-img>
                      </v-avatar>
                    </v-col>
                    <!-- Post Text -->
                    <v-col>
                      {{ post }}
                    </v-col>
                    <!-- Copy Button -->
                    <v-col cols="auto">
                      <v-btn icon @click="copyToClipboard(platformName, post)">
                        <v-icon>
                          {{
                            copiedPlatforms[platformName]
                              ? 'mdi-check'
                              : 'mdi-content-copy'
                          }}
                        </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </div>
          </div>

          <!-- Snackbar Notification -->
          <v-snackbar v-model="showSnackbar" :color="snackbarColor" timeout="3000" top right>
            {{ snackbarMessage }}
            <template v-slot:action="{ attrs }">
              <v-btn color="white" text @click="showSnackbar = false" v-bind="attrs">
                Close
              </v-btn>
            </template>
          </v-snackbar>

        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios';
import linkedinLogo from '@/assets/images/linkedin_logo.png';
import twitterLogo from '@/assets/images/twitter_logo.png';
import facebookLogo from '@/assets/images/facebook_logo.png';
import { useAuth0 } from '@auth0/auth0-vue';

export default {
  name: 'CompanySocialMediaPage',
  data() {
    return {
      // Company Description Data
      companyDescription: '',
      isLoading: false,
      isGenerating: false,
      descriptionSaved: false, // Tracks if a description is retrieved from the database
      // Social Media Generator Data
      socialPostContent: '',
      postTone: 50,
      isGeneratingPosts: false,
      posts: {},
      copiedPlatforms: {},
      platformLogos: {
        linkedin: linkedinLogo,
        twitter: twitterLogo,
        facebook: facebookLogo,
      },
      // Snackbar
      showSnackbar: false,
      snackbarMessage: '',
      snackbarType: 'success',
    };
  },
  async mounted() {
    try {
      const token = await this.getAccessTokenSilently();
      const response = await axios.get('/api/company-description', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // If a description is found in the database and it's not empty, set it
      if (response.data.description && response.data.description.trim().length > 0) {
        this.companyDescription = response.data.description;
        this.descriptionSaved = true;  // This will control visibility of Step 2
        this.descriptionOption = 'manual'; // Set to manual if description exists
      } else {
        this.descriptionSaved = false; // If no description is found or it's empty, Step 2 remains hidden
        this.companyDescription = '';   // Ensure the description is empty
      }
    } catch (error) {
      console.error('Error fetching company description:', error);
      this.descriptionSaved = false; // On error, also hide Step 2
      this.companyDescription = '';   // Ensure the description is empty
    }
  },
  setup() {
    const { getAccessTokenSilently } = useAuth0();
    return { getAccessTokenSilently };
  },
  computed: {
    snackbarColor() {
      return this.snackbarType === 'success' ? 'green' : 'red';
    },
  },
  methods: {
    async saveDescription() {
      this.isLoading = true;
      try {
        const token = await this.getAccessTokenSilently();
        await axios.post(
          '/api/company-description',
          { description: this.companyDescription },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        // Update descriptionSaved based on whether the description is empty
        this.descriptionSaved = this.companyDescription.trim().length > 0;
        this.showSnackbarNotification(
          this.descriptionSaved
            ? 'Company description saved successfully!'
            : 'Company description deleted successfully!',
          'success'
        );
      } catch (error) {
        console.error('Error saving company description:', error);
        this.showSnackbarNotification('Failed to save company description.', 'error');
      } finally {
        this.isLoading = false;
      }
    },
    // Social Media Generator Methods
    async generatePosts() {
      if (!this.companyDescription || !this.socialPostContent) return;
      this.isGeneratingPosts = true;
      try {
        const token = await this.getAccessTokenSilently();
        const response = await axios.post(
          '/api/generate-posts',
          {
            companyDescription: this.companyDescription,
            socialPostContent: this.socialPostContent,
            postTone: this.postTone,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.posts = response.data.posts;
        this.copiedPlatforms = {};
      } catch (error) {
        console.error('Error generating posts:', error);
        this.showSnackbarNotification('Failed to generate posts.', 'error');
      } finally {
        this.isGeneratingPosts = false;
      }
    },
    copyToClipboard(platformName, post) {
      navigator.clipboard.writeText(post);
      this.copiedPlatforms[platformName] = true;
      setTimeout(() => {
        this.copiedPlatforms[platformName] = false;
      }, 2000);
    },
    // Snackbar Notification
    showSnackbarNotification(message, type) {
      this.snackbarMessage = message;
      this.snackbarType = type;
      this.showSnackbar = true;
      setTimeout(() => {
        this.showSnackbar = false;
      }, 3000);
    },
  },
};
</script>